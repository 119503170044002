import {AxiosResponse} from "axios";
import {Map as ImmutableMap} from "immutable";
import {ISelectValue} from "../components/common/Select.component";
import {ClientsAndPlans, IClientInfo, IPlanInfo} from "../model/ClientsAndPlans.model";
import {ClientUser} from "../model/ClientUser.model";
import {convertDictionaryToArray} from "../utils/mapUtil";
import {ApiBase} from "./ApiBase";
import {IApiResult} from "./AxiosWealthManagementApi";
import {IPlanAuthorization} from "../model/PlanAuthorization.model";
import {AssetClassMapper} from "../components/research/research-filters/FilterAndSort.menuItems";

interface IClientInfoResponse {
    id: number;
    name: string;
    plans: {[key: number]: IPlanInfo};
}

export interface IClientsAndPlansResponse {
    [key: number]: IClientInfoResponse;
}

class PlanApi {
    private static convertToImmutableMap(dict: IClientsAndPlansResponse): ClientsAndPlans {
        return convertDictionaryToArray(dict)
            .reduce(
                PlanApi.plansFromClientInfoReducer,
                ImmutableMap<number, IClientInfo>() as ClientsAndPlans,
            );
    }

    private static plansFromClientInfoReducer(old: ImmutableMap<number, IClientInfo>, client: IClientInfoResponse) {
        const planPairsArray: Iterable<[number, IPlanInfo]> =
            convertDictionaryToArray(client.plans).map((it) => [it.id, it]);
        const currentPlansMap = ImmutableMap<number, IPlanInfo>(planPairsArray);

        const clientInfo: IClientInfo = {id: client.id, name: client.name, plans: currentPlansMap};
        return old.set(clientInfo.id, clientInfo);
    }

    public removeProductFromPlanPortfolio(productId: number): Promise<void> {
        return new Promise<void>((resolve) => {
            ApiBase.processDelete(`/plans/current/products/${productId}`)
                .then(() => resolve());
        });
    }

    public addProductToPlanPortfolio(productId: number): Promise<void> {
        return new Promise<void>((resolve) => {
            ApiBase.processPut(`/plans/current/products/${productId}`)
                .then(() => resolve());
        });
    }

    public getListOfCurrentClientsPlans(currentPlanId: number): Promise<IApiResult<IPlanInfo[]>> {
        return ApiBase.processGet("/clients/current/plans", (axiosResponse: AxiosResponse): IPlanInfo[] => {
            return axiosResponse.data.map((plan: IPlanInfo) => ({...plan, selected: plan.id === currentPlanId}))
                .sort((a: IPlanInfo, b: IPlanInfo) => {
                    return a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase());
                });
        });
    }

    public requestClientsAndPlans(): Promise<IApiResult<ClientsAndPlans>> {
        return ApiBase.processGet("/clients", (response: AxiosResponse) => {
            return PlanApi.convertToImmutableMap(response.data);
        });
    }

    public requestClientUsers(): Promise<ClientUser[]> {
        return ApiBase.processGetUnwrapped<ClientUser[]>(
                "/clients/current/users",
                (response: AxiosResponse<ClientUser[]>): ClientUser[] => {
                    return response.data.map((user: ClientUser): ClientUser => {
                        return new ClientUser(
                            user.id,
                            user.firstName,
                            user.lastName,
                            user.userType,
                            user.email,
                            user.planIds,
                        );
                    });
                });
    }

    public requestCountries(): Promise<ISelectValue[]> {
        return ApiBase.processGetUnwrapped("/countries",
            (response: AxiosResponse) => {
                return response.data;
        });
    }

    public savePlan(plan: IPlanAuthorization): Promise<IApiResult<boolean>> {
        return  ApiBase.processPost("/plans", plan);
    }

    public requestPlan(planId: number): Promise<IPlanAuthorization> {
        return ApiBase.processGetUnwrapped(`/plans/${planId}`,
            (response: AxiosResponse) => {
                return this.mapPlanAuthorization(response);
            });
    }

    public updatePlan(planId: number, planAuthorization: IPlanAuthorization): Promise<IApiResult<boolean>> {
        return ApiBase.processPost(`/plans/${planId}`, planAuthorization);
    }

    public savePlanTypeId(planTypeId: number){
        return ApiBase.processPatch(`/plan/plan-type`, {planTypeId})
            .then(() => Promise.resolve());
    }

    public hasPortfolioAccess(planId: number): Promise<boolean> {
        return ApiBase.processGetUnwrapped(`/plan/portfolio-access/${planId}`,
            (response: AxiosResponse) => {
                return response.data;
            });
    }

    private mapPlanAuthorization = (response: AxiosResponse): IPlanAuthorization => {
        return {
            ...response.data,
            planResearchAuthorization: response.data.planResearchAuthorization ? {
                ...response.data.planResearchAuthorization,
                assetClasses: response.data.planResearchAuthorization.assetClasses
                    .map((it: string) => AssetClassMapper[it]),
            } : null
        };
    };
}

export default new PlanApi();
