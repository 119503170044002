import * as React from "react";
import {useEffect, useState} from "react";
import {formatCurrency} from "../../utils/numberUtil";
import {PortfolioApi} from "../../api/PortfolioApi";
import {getTotalPlanNode} from "./portfolioUtil";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {ErrorComponent} from "../base/Error.component";
import {IPlanTreeNodeFlat} from "../../model/Portfolio.model";
import {NewSelectComponent} from "../common/NewSelect.component";
import {compareDates, formatMonthYearDate, parseDate} from "../../utils/dateUtil";
import {ISelectValue} from "../common/Select.component";
import {PlanTreeTable} from "./PlanTreeTable";
import {hasConsultantLevelAccess, hasPortfolioPageAccess} from "../../utils/sessionUtil";
import {useDispatch, useSelector} from "react-redux";
import {
    hasClientPortfolioAccess,
    hasPortfolioAccess,
} from "../../../mainReducerMapSelectors";
import {NoAccessNotification} from "../base/header/NoAccessNotification";
import {setTreeDateAction} from "./PortfolioActions";
import {portfolioPlanTreeSelectedDate} from "./PortfolioMapSelectors";

export const PortfolioPage: React.FunctionComponent = () => {
    const portfolioAccess = useSelector(state => hasPortfolioAccess(state));
    const clientPortfolioAccess = useSelector(state => hasClientPortfolioAccess(state));
    const dateOptionFromStore = useSelector(state => portfolioPlanTreeSelectedDate(state));

    const [nodes, setNodes] = React.useState<IPlanTreeNodeFlat[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<boolean>(false);
    const [currentDateOption, setCurrentDateOption] = useState<ISelectValue | undefined>(undefined);
    const [dateOptions, setDateOptions] = useState<ISelectValue[]>([]);

    const dispatch = useDispatch();

    const setDates = (nodes: IPlanTreeNodeFlat[]) => {
        const dates = Array.from(new Set( nodes
            .map((node) => node.date)))
            .sort((a, b) => compareDates(parseDate(a.toString()), parseDate(b.toString())));

        const dateOptions: ISelectValue[] = dates.map((date) => (
            {name: formatMonthYearDate(date), id: date})
        );
        setDateOptions(dateOptions);
        setCurrentDateOption(dateOptionFromStore ? dateOptionFromStore : dateOptions[0]);
    };

    useEffect(() => {
        PortfolioApi.getCurrentPlanPortfolio()
            .then((response) => {
                setDates(response);
                setNodes(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setError(true);
            });
    }, []);

    useEffect(() => {
        if (currentDateOption) dispatch(setTreeDateAction(currentDateOption));
    }, [currentDateOption]);

    const handleDateChange = (e: any) => {
        setCurrentDateOption(dateOptions.find((date) => date.id === e.target.value));
    };

    const renderDateSelection = () => {
        return <div className="portfolio__date-selection" data-testid="portfolio__date-selection">
                <div className="portfolio__label-date">Date</div>
                <NewSelectComponent
                    id={"portfolio__dates"}
                    values={dateOptions}
                    selected={currentDateOption?.id}
                    width={200}
                    handleChange={handleDateChange}
                    classNameSuffix="filter-menu_portfolio-dates"
                />
        </div>;
    };

    const renderPlanTreeTable = () => {
        return currentDateOption &&
            <PlanTreeTable selectedDate={currentDateOption!} datedPlanNodes={nodes}/>;
    };

    const renderTotalAssets = () => {
        const totalNode = getTotalPlanNode(nodes, currentDateOption?.id as string);
        return <div data-testid="portfolio__total-assets" className="portfolio__total-assets">
            {totalNode === undefined ? "" : formatCurrency(totalNode!.marketValue)}
        </div>;
    };

    const renderClientAccessNotification = () => {
        return (hasConsultantLevelAccess() && !clientPortfolioAccess)
            ? <div className="portfolio-page__notification" data-testid={"portfolio-page__notification"}>
                <NoAccessNotification
                    message={"The Portfolio page is currently hidden from client users of this plan."}/>
            </div>
            : null;
    };

    const renderContent = () =>
        <div className="main-content new-common-styles portfolio__page-container"
             data-testid="portfolio__page-container">
            {renderClientAccessNotification()}
            <h1>Portfolio Overview</h1>
            <div className="portfolio__title-selection">
                <div>
                    <div className="portfolio__label"><h3>Total Assets</h3></div>
                    {renderTotalAssets()}
                </div>
                {renderDateSelection()}
            </div>
            {renderPlanTreeTable()}
        </div>;

    return hasPortfolioPageAccess(portfolioAccess, clientPortfolioAccess)
    ? loading
            ? <LoadingSpinner/>
            : error
                ? <ErrorComponent/>
                : renderContent()
        : <ErrorComponent/>;
};