import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ProductSummary} from "../../model/product/ProductSummary";
import {FormattedMessage} from "react-intl";
import {Map} from "immutable";
import {MRT_Cell, MRT_ColumnDef,} from "material-react-table";
import {Link} from "react-router-dom";
import {IManagerStrategiesState} from "./ManagerStrategiesReducer";
import managerStrategiesActions from "./ManagerStrategiesActions";
import {IApplicationRootState} from "../../../applicationState";
import {scrollToElement} from "../../utils/browserUtil";
import {IMrtTableProps, MrtTableComponent} from "../common/MrtTable.component";
import {DEFAULT_PAGINATION, IMrtTableColumnFilter, IMrtTablePagination} from "../../model/MrtTableState.model";
import {getTranslation} from "../../utils/translationUtil";
import {byRatingAndName, fixBlankAndTranslate} from "../../utils/researchUtil";

export interface IManagerStrategiesProps {
    managerId: number;
    productSummaries: Map<number, ProductSummary>;
}

export const ManagerStrategies: React.FunctionComponent<IManagerStrategiesProps> = (props) => {
    const [data, setData] = useState<ProductSummary[]>([]);
    const [resetTable, setResetTable] = useState<boolean>(false);

    const dispatch = useDispatch();

    const strategiesTableStateFromStore: IManagerStrategiesState | undefined
        = useSelector((state: IApplicationRootState) => state.managerStrategiesState);

    const validStateFromStore: boolean = strategiesTableStateFromStore?.managerId === props.managerId;

    useEffect(() => {
        const productSummaries = getAllProductSummariesForManager(props.managerId).sort(byRatingAndName);
        setData(productSummaries);
    }, [props]);

    useEffect(() => {
        if (strategiesTableStateFromStore?.managerId && strategiesTableStateFromStore.managerId !== props.managerId) {
            dispatch(managerStrategiesActions.clearStrategiesAction());
            setResetTable(true);
        }
    }, [props.managerId]);

    useEffect(() => {
        if (strategiesTableStateFromStore?.managerId && strategiesTableStateFromStore.managerId === props.managerId) {
            if (strategiesTableStateFromStore.scrollToBackstopId) {
                setTimeout(() => {
                    scrollToElement(`#product_${strategiesTableStateFromStore.scrollToBackstopId}`, 100, true);
                }, (50));
            }
        }
    }, []);

    const getAllProductSummariesForManager = (managerId: number): ProductSummary[] => {
        return props.productSummaries
            .filter((it: ProductSummary) => it ? it.manager.managerBackstopId === managerId : false)
            .valueSeq()
            .toArray()
            .map((it: ProductSummary) => fixBlankAndTranslate(it));
    };

    const strategyNameLink = ({cell}: { cell: MRT_Cell<ProductSummary> }) => {
        const productName = cell.getValue<string>();
        const backstopId = cell.row.original.backstopId;
        return (
            <Link to={`/products/${backstopId}`}
                  onClick=
                      {() => dispatch(managerStrategiesActions.setScrollToBackstopAction(props.managerId, backstopId))}
            >
                <div id={`product_${backstopId}`}>{productName}</div>
            </Link>
        );
    };

    const columns: MRT_ColumnDef<ProductSummary>[] = [
        {
            accessorKey: "name",
            header: getTranslation("research-page.strategy-name", "Strategy Name"),
            Cell: strategyNameLink,
            filterVariant: "text",
            size: 200
        },
        {accessorKey: "assetType", header: getTranslation("research-page.asset-class", "Asset Class"), size: 100},
        {accessorKey: "productRating", header: getTranslation("research-page.rating", "Rating"), size: 100},
        {accessorKey: "approach", header: getTranslation("research-page.approach", "Approach"), size: 100},
        {accessorKey: "category", header: getTranslation("research-page.category", "Category"), size: 100},
        {accessorKey: "region", header: getTranslation("research-page.region", "Region"), size: 100},
        {accessorKey: "style", header: getTranslation("research-page.style", "Style"), size: 100},
    ];

    const tableHeader = () => {
        return <h2 className="title-medium gray">
            <FormattedMessage id="manager.strategies" defaultMessage="Strategies"/>
        </h2>;
    };

    const onPaginationChange = (pagination: IMrtTablePagination) => {
        dispatch(managerStrategiesActions.updatePaginationAction(props.managerId, pagination));
        setResetTable(false);
    };

    const onGlobalFilterChange = (value: string) => {
        dispatch(managerStrategiesActions.updateGlobalFilterAction(props.managerId, value));
        setResetTable(false);
    };

    const onColumnFiltersChange = (filters: IMrtTableColumnFilter[]) => {
        dispatch(managerStrategiesActions.updateFiltersAction(props.managerId, filters));
        setResetTable(false);
    };

    const tableOptions: IMrtTableProps<ProductSummary> = {
        columns,
        data,
        header: tableHeader,
        columnFilters: validStateFromStore ? strategiesTableStateFromStore!.columnFilters : [],
        globalFilter: validStateFromStore ? strategiesTableStateFromStore!.globalFilterState : "",
        pagination: validStateFromStore ? strategiesTableStateFromStore!.pagination : DEFAULT_PAGINATION,
        onColumnFiltersChange,
        onGlobalFilterChange,
        onPaginationChange,
        reset: resetTable
    };

    return <div className="strategies-container" data-testid="strategies-container">
        <MrtTableComponent {...tableOptions}/>
    </div>;
};