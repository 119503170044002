import {Action, handleActions} from "redux-actions";
import {ManagerStrategiesActionTypes} from "./ManagerStrategiesActions";
import {DEFAULT_PAGINATION, IMrtTableState} from "../../model/MrtTableState.model";

export interface IManagerStrategiesState extends IMrtTableState{
    managerId: number | undefined;
}

export const initialState: IManagerStrategiesState = {
    managerId: undefined,
    columnFilters: [],
    globalFilterState: "",
    pagination: DEFAULT_PAGINATION,
    scrollToBackstopId: undefined,
};

const setScrollToBackstopId = (state: IManagerStrategiesState, action: Action<IManagerStrategiesState>):
    IManagerStrategiesState => {
    return {
        ...state,
        managerId: action.payload ? action.payload.managerId : initialState.managerId,
        scrollToBackstopId: action.payload ? action.payload.scrollToBackstopId : initialState.scrollToBackstopId,
    };
};

const updatePagination = (state: IManagerStrategiesState, action: Action<IManagerStrategiesState>):
    IManagerStrategiesState => {
    return {
        ...state,
        managerId: action.payload ? action.payload.managerId : initialState.managerId,
        pagination: action.payload ? action.payload.pagination : initialState.pagination,
    };
};

const updateFilters = (state: IManagerStrategiesState, {payload}: Action<IManagerStrategiesState>):
        IManagerStrategiesState => {
        return {
        ...state,
        managerId: payload ? payload.managerId : initialState.managerId,
        columnFilters: payload ? payload.columnFilters : initialState.columnFilters,
    };
};

const updateGlobalFilter = (state: IManagerStrategiesState, {payload}: Action<IManagerStrategiesState>):
    IManagerStrategiesState => {
    return {
        ...state,
        managerId: payload ? payload.managerId : initialState.managerId,
        globalFilterState: payload ? payload.globalFilterState : initialState.globalFilterState
    };
};

const clearStrategies = (state: IManagerStrategiesState, {}: Action<{}>): IManagerStrategiesState => {
    return {
        ...state,
        managerId: initialState.managerId,
        columnFilters: initialState.columnFilters,
        globalFilterState: initialState.globalFilterState,
        pagination: initialState.pagination,
        scrollToBackstopId: initialState.scrollToBackstopId,
    };
};

const reducerMap = {
    [ManagerStrategiesActionTypes.UPDATE_MANAGER_STRATEGIES_FILTERS]: updateFilters,
    [ManagerStrategiesActionTypes.CLEAR_MANAGER_STRATEGIES]: clearStrategies,
    [ManagerStrategiesActionTypes.UPDATE_MANAGER_STRATEGIES_GLOBAL_FILTER]: updateGlobalFilter,
    [ManagerStrategiesActionTypes.UPDATE_MANAGER_STRATEGIES_PAGINATION]: updatePagination,
    [ManagerStrategiesActionTypes.SET_MANAGER_STRATEGIES_SCROLL_TO]: setScrollToBackstopId,
};

export default handleActions<IManagerStrategiesState, any>(reducerMap, initialState);
