import {IAllManagersStore, initialState as allManagersInitialState} from "./main/components/manager/AllManagersReducer";
import {
    allProductStoreInitialState as allProductsInitialState,
    IAllProductsStore
} from "./main/components/product/AllProductsReducer";
import {
    IAllMeetingNotesStore,
    initialState as allMeetingNotesInitialState
} from "./main/components/meeting-note/AllMeetingNotesReducer";
import {
    IAllFlashReportsStore,
    initialState as allFlashReportsInitialState
} from "./main/components/flash-report/AllFlashReportsReducer";
import {
    IAllCapitalMarketAssumptionsStore,
    initialState as allCapitalMarketAssumptionsInitialState
} from "./main/components/capital-market-assumptions/AllCapitalMarketAssumptionsReducer";
import {
    IFeedbackPageState,
    initialState as feedbackPageInitialState
} from "./main/components/feedback/feedbackPageReducer";
import {
    IFlashReportState,
    initialState as flashReportInitialState
} from "./main/components/flash-report/flashReportReducer";
import {
    IMeetingNoteState,
    initialState as meetingNoteInitialState
} from "./main/components/meeting-note/meetingNoteReducer";
import {initialState as productInitialState, IProductState} from "./main/components/product/productReducer";
import {initialState as productStoreInitialState, IProductStore} from "./main/components/research/productStoreReducer";
import {IHeaderReducerState, initialState as headerInitialState} from "./main/components/base/header/HeaderReducer";
import {
    ISharedDocumentsPropsFromState,
    sharedDocumentsInitialState
} from "./main/components/shared-documents/SharedDocuments.reducer";
import {ISessionState, sessionInitialState} from "./main/components/session/SessionReducer";
import {
    IFilterState,
    initialState as filterInitialState
} from "./main/components/research/research-filters/filterReducer";
import {
    initialState as strategyUpdatesInitialState,
    IStrategyUpdatesState
} from "./main/components/research/strategy-update/strategyUpdatesReducer";
import {
    initialState as portfolioInitialState,
    IPortfolioAdminState
} from "./main/components/research/edit-portfolio/editPortfolioReducer";
import {IAdminState, initialState as adminInitialState} from "./main/components/admin/AdminReducer";
import {
    currentClientInitialState as clientInitialState,
    ICurrentClientState
} from "./main/components/client/ClientReducer";
import {RequestState} from "./main/components/common/commonStates";
import {IPlanSettings} from "./main/api/AuthApi";
import {
    initialState as thoughtLeadershipInitialState,
    IThoughtLeadershipState
} from "./main/components/thought-leadership/ThoughtLeadershipReducer";
import {
    IHoldingsState,
    initialState as holdingsInitialState
} from "./main/components/investments/holdings/HoldingsReducer";
import {IOddIQStore, oddIQInitialState} from "./main/components/odd-iq/OddIqReducer";
import {
    IPowerBiReportingStore,
    powerBiReportingInitialState
} from "./main/components/power-bi-reporting/PowerBiReporting.reducer";
import {calendarInitialState, ICalendarStore} from "./main/components/calendar/Calendar.reducer";
import {cprPortfolioInitialState, ICprPortfolioStore} from "./main/components/home-page/CprPortfolioReducer";
import {initialState as planSettingsInitialState} from "./main/components/root/PlanSettings.reducer";
import {initialState as managerStrategiesInitialState} from "./main/components/manager/ManagerStrategiesReducer";
import {IManagerStrategiesState} from "./main/components/manager/ManagerStrategiesReducer";
import {IPortfolioPlanTreeState, portfolioPlanTreeInitialState} from "./main/components/portfolio/PortfolioReducer";
import {IMrtTableState} from "./main/model/MrtTableState.model";
import {initialState as researchPageInitialState} from "./main/components/research/ResearchPageReducer";

export interface IApplicationRootState {
    allManagersStore?: IAllManagersStore;
    allProductsStore?: IAllProductsStore;
    allMeetingNotesStore?: IAllMeetingNotesStore;
    allFlashReportsStore?: IAllFlashReportsStore;
    allCapitalMarketAssumptionsStore?: IAllCapitalMarketAssumptionsStore;
    feedbackState?: IFeedbackPageState;
    flashReportState?: IFlashReportState;
    meetingNoteRootState?: IMeetingNoteState;
    productPageState?: IProductState;
    productStore?: IProductStore;
    headerRootState?: IHeaderReducerState;
    sharedDocumentsRootState?: ISharedDocumentsPropsFromState;
    session?: ISessionState;
    filterAndSortState?: IFilterState;
    strategyUpdatesPageState?: IStrategyUpdatesState;
    portfolioAdminState?: IPortfolioAdminState;
    adminState?: IAdminState;
    currentClient?: ICurrentClientState;
    planSettings?: RequestState<IPlanSettings>;
    thoughtLeadership?: IThoughtLeadershipState;
    holdingsState?: IHoldingsState;
    oddIQStore?: IOddIQStore;
    powerBiReportingStore?: IPowerBiReportingStore;
    calendarStore?: ICalendarStore;
    cprPortfolioStore?: ICprPortfolioStore;
    managerStrategiesState?: IManagerStrategiesState;
    portfolioPlanTreeState?: IPortfolioPlanTreeState;
    researchPageState?: IMrtTableState;
}

export const applicationInitialState: IApplicationRootState = {
    allManagersStore: allManagersInitialState,
    allProductsStore: allProductsInitialState,
    allMeetingNotesStore: allMeetingNotesInitialState,
    allFlashReportsStore: allFlashReportsInitialState,
    allCapitalMarketAssumptionsStore: allCapitalMarketAssumptionsInitialState,
    feedbackState: feedbackPageInitialState,
    flashReportState: flashReportInitialState,
    meetingNoteRootState: meetingNoteInitialState,
    productPageState: productInitialState,
    productStore: productStoreInitialState,
    headerRootState: headerInitialState,
    sharedDocumentsRootState: sharedDocumentsInitialState,
    session: sessionInitialState,
    filterAndSortState: filterInitialState,
    strategyUpdatesPageState: strategyUpdatesInitialState,
    portfolioAdminState: portfolioInitialState,
    adminState: adminInitialState,
    currentClient: clientInitialState,
    planSettings: planSettingsInitialState,
    thoughtLeadership: thoughtLeadershipInitialState,
    holdingsState: holdingsInitialState,
    oddIQStore: oddIQInitialState,
    powerBiReportingStore: powerBiReportingInitialState,
    calendarStore: calendarInitialState,
    cprPortfolioStore: cprPortfolioInitialState,
    managerStrategiesState: managerStrategiesInitialState,
    portfolioPlanTreeState: portfolioPlanTreeInitialState,
    researchPageState: researchPageInitialState
};