export const DEFAULT_PAGINATION = {pageIndex: 0, pageSize: 10};

export interface IMrtTablePagination {
    pageIndex: number;
    pageSize: number;
}

export interface IMrtTableColumnFilter {
    id: string
    value: unknown
}

export interface IMrtTableState
{
    columnFilters: IMrtTableColumnFilter[];
    globalFilterState: string;
    pagination: IMrtTablePagination;
    scrollToBackstopId: number | undefined;
}