import * as React from "react";
import {Map} from "immutable";
import {ProductSummary} from "../../model/product/ProductSummary";
import {getSuccessData} from "../common/commonStates";
import {portfolioProductSummariesSelector} from "../../../mainReducerMapSelectors";
import {IApplicationRootState} from "../../../applicationState";
import {connect, useDispatch, useSelector} from "react-redux";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {allProductsRequestPair} from "../common/RequesterPairs";
import {IMrtTableProps, MrtTableComponent} from "../common/MrtTable.component";
import {MRT_Cell, MRT_ColumnDef} from "material-react-table";
import {FormattedMessage} from "react-intl";
import {
    DEFAULT_PAGINATION,
    IMrtTableColumnFilter,
    IMrtTablePagination,
    IMrtTableState
} from "../../model/MrtTableState.model";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {byName} from "../../utils/listUtil";
import researchPageActions from "./ResearchPageActions";
import {scrollToElement} from "../../utils/browserUtil";
import {getTranslation} from "../../utils/translationUtil";
import {fixBlankAndTranslate} from "../../utils/researchUtil";

export interface INewResearchPropsFromStore {
    productSummaries: Map<number, ProductSummary>;
}

export const NewResearchPage: React.FunctionComponent<INewResearchPropsFromStore> = (props) => {
    const [data, setData] = useState<ProductSummary[]>([]);
    const [resetTable, setResetTable] = useState<boolean>(false);

    const dispatch = useDispatch();

    const researchTableStateFromStore: IMrtTableState | undefined
        = useSelector((state: IApplicationRootState) => state.researchPageState);

    useEffect(() => {
        const productSummaries = getAllProductSummaries().sort(byName);
        setData(productSummaries);
    }, [props]);

    useEffect(() => {
        if (researchTableStateFromStore?.scrollToBackstopId) {
            setTimeout(() => {
                scrollToElement(`#id_${researchTableStateFromStore.scrollToBackstopId}`, 100, true);
            }, (50));
        }
    }, []);

    const managerNameLink = ({cell}: { cell: MRT_Cell<ProductSummary> }) => {
        const mangerName = cell.getValue<string>();
        const backstopId = cell.row.original.manager.managerBackstopId;
        return (
            <Link to={`/managers/${backstopId}`}
                  onClick={() => dispatch(researchPageActions.setScrollToBackstopAction(backstopId))}
            >
                <div id={`id_${backstopId}`}>{mangerName}</div>
            </Link>
        );
    };
    const strategyNameLink = ({cell}: { cell: MRT_Cell<ProductSummary> }) => {
        const productName = cell.getValue<string>();
        const backstopId = cell.row.original.backstopId;
        return (
            <Link to={`/products/${backstopId}`}
                  onClick={() => dispatch(researchPageActions.setScrollToBackstopAction(backstopId))}>
                <div id={`id_${backstopId}`}>{productName}</div>
            </Link>
        );
    };

    const dataMapper = (productSummary: ProductSummary) => {
        return {
            ...productSummary,
            managerName: productSummary.manager.name,
            oddRatingCurrent: productSummary.oddRating.rating,
        };
    };

    const getAllProductSummaries = (): ProductSummary[] => {
        return props.productSummaries
            .map(dataMapper)
            .valueSeq()
            .toArray()
            .map((it: ProductSummary) => fixBlankAndTranslate(it));
    };

    const columns: MRT_ColumnDef<ProductSummary>[] = [
        {
            accessorKey: "managerName",
            header: getTranslation("research-page.manager-name", "Manager Name"),
            Cell: managerNameLink,
            size: 200,
            filterVariant: "text"
        },
        {
            accessorKey: "name",
            header: getTranslation("research-page.strategy-name", "Strategy Name"),
            Cell: strategyNameLink,
            size: 100,
            filterVariant: "text"
        },
        {
            accessorKey: "assetType",
            header: getTranslation("research-page.asset-class", "Asset Class"),
            enableEditing: true,
            size: 100
        },
        {accessorKey: "productRating", header: getTranslation("research-page.rating", "Rating"), size: 100},
        {accessorKey: "oddRatingCurrent", header: getTranslation("research-page.odd-rating", "ODD Rating"), size: 100},
        {accessorKey: "approach", header: getTranslation("research-page.approach", "Approach"), size: 100},
        {accessorKey: "category", header: getTranslation("research-page.category", "Category"), size: 100},
        {accessorKey: "region", header: getTranslation("research-page.region", "Region"), size: 100},
        {accessorKey: "style", header: getTranslation("research-page.style", "Style"), size: 100},
    ];

    const tableHeader = () => {
        return <h2 className="title-medium gray">
            <FormattedMessage id="research-page.name" defaultMessage="New Research"/>
        </h2>;
    };

    const onPaginationChange = (pagination: IMrtTablePagination) => {
        dispatch(researchPageActions.updatePaginationAction(pagination));
        setResetTable(false);
    };

    const onGlobalFilterChange = (value: string) => {
        dispatch(researchPageActions.updateGlobalFilterAction(value));
        setResetTable(false);
    };

    const onColumnFiltersChange = (filters: IMrtTableColumnFilter[]) => {
        dispatch(researchPageActions.updateColumnFiltersAction(filters));
        setResetTable(false);
    };

    const tableOptions: IMrtTableProps<ProductSummary> = {
        columns,
        data,
        header: tableHeader,
        onColumnFiltersChange,
        onGlobalFilterChange,
        onPaginationChange,
        columnFilters: researchTableStateFromStore?.columnFilters ? researchTableStateFromStore.columnFilters : [],
        globalFilter: researchTableStateFromStore?.globalFilterState
            ? researchTableStateFromStore!.globalFilterState
            : "",
        pagination: researchTableStateFromStore?.pagination
            ? researchTableStateFromStore.pagination
            : DEFAULT_PAGINATION,
        reset: resetTable,
    };

    return <div className="new-research__container" data-testid="new-research__container">
        <MrtTableComponent {...tableOptions}/>
    </div>;
};

export const mapStateToProps = (state: IApplicationRootState): INewResearchPropsFromStore => {
    return {
        productSummaries: getSuccessData(portfolioProductSummariesSelector(state))!,
    };
};

const connectedNewResearchPage = connect<INewResearchPropsFromStore, null>(mapStateToProps)(NewResearchPage);

export default multipleApiRequesterWrapper(
    connectedNewResearchPage,
    [
        allProductsRequestPair()
    ],
);