import * as React from "react";
import {RadioButtonsComponent} from "../common/RadioButtons.component";
import {Map as ImmutableMap, Set} from "immutable";
import {SelectComponentReact} from "../common/ReactSelectComponent";
import {ProductSummary} from "../../model/product/ProductSummary";
import {ChipsList} from "../common/ChipsList.component";
import {CheckBoxComponent} from "../common/CheckBox.component";
import {AssetClass} from "../research/research-filters/FilterAndSort.menuItems";

export interface IAdminCreatePlanResearchComponent {
    products: ImmutableMap<number, ProductSummary>;
    submitClicked: boolean;
    allAccess: boolean;
    setAllAccess: (value: boolean) => void;
    selectedProducts: ProductSummary[];
    setSelectedProducts: (value: ProductSummary[]) => void;
    dynamicResearchEnabled: boolean;
    setDynamicResearchEnabled: (value: boolean) => void;
    assetClasses: string[];
    setAssetClasses: (value: string[]) => void;
    fundamentallyRatedOnly: boolean;
    setFundamentallyRatedOnly: (value: boolean) => void;
    buyRatedOnly: boolean;
    setBuyRatedOnly: (value: boolean) => void;
}

export const RESEARCH_ALL_ACCESS__ENABLED = "Research All Access Enabled";
export const DYNAMIC_RESEARCH_ENABLED = "Dynamic Research Enabled";
export const SPECIFIC_ASSET_CLASSES_ONLY = "Specific Asset Classes Only";
export const FUNDAMENTALLY_RATED_ONLY = "Fundamentally Rated Only";
export const BUY_RATED_ONLY = "Buy Rated Only";

export const AdminCreatePlanResearchComponent: React.FunctionComponent<IAdminCreatePlanResearchComponent> = (props) => {
    const productsList: ProductSummary[] = props.products.map((value) => value!).valueSeq().toArray();

    function renderDynamicResearchToggle() {
        const name = "dynamic-research-enabled";
        return <RadioButtonsComponent
            id="admin-create-plan__dynamic-research-enabled"
            currentValue={props.dynamicResearchEnabled}
            onChange={(value) => props.setDynamicResearchEnabled(value)}
            classNamePrefix={`admin-create-plan__${name}`}
            name={name}
        />;
    }

    function renderCheckbox(name: string) {
        const onchangeHandler = () => {
            const assetClasses = props.assetClasses.includes(name)
                ? props.assetClasses.filter((assetClass) => assetClass !== name)
                : [...props.assetClasses, name];
            props.setAssetClasses(assetClasses);
        };

        return <CheckBoxComponent
            name={name}
            checked={props.assetClasses.includes(name)}
            onChange={onchangeHandler}
            label={name}
        />;
    }

    function renderAssetClassesSelection() {
        return <div className="admin-create-plan__assets-classes-container">
            <div className="admin-client-plan__label">{SPECIFIC_ASSET_CLASSES_ONLY}</div>
            {renderCheckbox(AssetClass.EQUITY)}
            {renderCheckbox(AssetClass.FIXED_INCOME)}
            {renderCheckbox(AssetClass.LIQUID_ALTERNATIVE)}
            {renderCheckbox(AssetClass.MULTIASSET_DGFTTAA)}
            {renderCheckbox(AssetClass.CASH)}
        </div>;
    }

    function renderFundamentallyRatedSection() {
        const name = "dynamic-research-fundamentally-rated";
        return <div className="admin-create-plan__fundamentally-rated-container">
            <div className="admin-client-plan__label">{FUNDAMENTALLY_RATED_ONLY}</div>
            <RadioButtonsComponent
                id = "admin-create-plan__dynamic-research-fundamentally-rated"
                currentValue = {props.fundamentallyRatedOnly}
                onChange = {(value) => props.setFundamentallyRatedOnly(value)}
                classNamePrefix = {`admin-create-plan__${name}`}
                name = {name}
                yesLabel = "Yes, I want to see only Fundamentally rated products"
                noLabel = "No, I want see both Fundamentally and Inform rated products"
            />
        </div>;
    }

    function renderBuyRatedSection() {
        const name = "dynamic-research-buy-rated";
        return <div className="admin-create-plan__buy-rated-container">
            <div className="admin-client-plan__label">{BUY_RATED_ONLY}</div>
            <RadioButtonsComponent
                id = "admin-create-plan__buy-rated-only"
                currentValue = {props.buyRatedOnly}
                onChange = {(value) => props.setBuyRatedOnly(value)}
                classNamePrefix = {`admin-create-plan__${name}`}
                name = {name}
                yesLabel = "Yes, I want to see only Buy rated products"
                noLabel = "No, I want see any rating"
            />
        </div>;
    }

    function renderDynamicResearchSection() {
        return <div data-testid="dynamic-research-enabled-container">
                <div className="admin-client-plan__label">{DYNAMIC_RESEARCH_ENABLED}</div>
                {renderDynamicResearchToggle()}
                {props.dynamicResearchEnabled && renderAssetClassesSelection()}
                {props.dynamicResearchEnabled && renderFundamentallyRatedSection()}
                {props.dynamicResearchEnabled && renderBuyRatedSection()}
            </div>;
    }

    function renderAllAccessToggle() {
        const name = "all-access";
        return <RadioButtonsComponent
            id="admin-create-plan__all-access-toggle"
            currentValue={props.allAccess}
            onChange={(value) => props.setAllAccess(value)}
            classNamePrefix={`admin-create-plan__${name}`}
            name={name}
        />;
    }

    function renderProductsDropdown() {
        const handleChange = (e: any) => {
            const selectedProductsArray = Set([...props.selectedProducts, e]).toArray();
            props.setSelectedProducts(selectedProductsArray);
        };

        return <SelectComponentReact
            className="admin-create-plan__products-dropdown"
            options={productsList}
            menuIsOpen={undefined}
            value={null}
            placeholder="Search"
            onChange={handleChange}
            submitClicked={props.submitClicked}
            renderRequired={false}
            stylesObject={{width: "280px"}}
            getOptionLabel={(option: ProductSummary) => option.name}
            getOptionValue={(option: ProductSummary) => option.backstopId.toString()}/>;
    }

    function renderChipList() {
        return <ChipsList className="admin-create-plan__products-chips"
                          values={props.selectedProducts.map((product) => product.name)}
                          onRemove={(value) => props.setSelectedProducts(
                              props.selectedProducts.filter((product) => product.name !== value))}/>;
    }

    function renderProductsSelection() {
        if (props.allAccess) {
            return;
        }
        return <div>
            <br/>
            <div className="admin-client-plan__label">Products</div>
            {renderProductsDropdown()}
            {renderChipList()}
        </div>;
    }

    function renderResearchSection() {
        return props.dynamicResearchEnabled
            ? null
            : <div data-testid="research-all-access-enabled">
                <div className="admin-client-plan__label">{RESEARCH_ALL_ACCESS__ENABLED}</div>
                {renderAllAccessToggle()}
                {renderProductsSelection()}
                <br/>
            </div>;
    }

    return <div data-testid="create-plan-research-component">
        {renderDynamicResearchSection()}
        {renderResearchSection()}
    </div>;
};