import {Action, handleActions} from "redux-actions";
import {DEFAULT_PAGINATION, IMrtTableState} from "../../model/MrtTableState.model";
import {ResearchPageActionTypes} from "./ResearchPageActions";

export const initialState: IMrtTableState = {
    columnFilters: [],
    globalFilterState: "",
    pagination: DEFAULT_PAGINATION,
    scrollToBackstopId: undefined,
};

const setScrollToBackstopId = (state: IMrtTableState, action: Action<IMrtTableState>): IMrtTableState => {
    return {
        ...state,
        scrollToBackstopId: action.payload ? action.payload.scrollToBackstopId : initialState.scrollToBackstopId,
    };
};

const updatePagination = (state: IMrtTableState, action: Action<IMrtTableState>):
    IMrtTableState => {
    return {
        ...state,
        pagination: action.payload ? action.payload.pagination : initialState.pagination,
    };
};

const updateColumnFilters = (state: IMrtTableState, {payload}: Action<IMrtTableState>):
    IMrtTableState => {
    return {
        ...state,
        columnFilters: payload ? payload.columnFilters : initialState.columnFilters,
    };
};

const updateGlobalFilter = (state: IMrtTableState, {payload}: Action<IMrtTableState>):
    IMrtTableState => {
    return {
        ...state,
        globalFilterState: payload ? payload.globalFilterState : initialState.globalFilterState
    };
};

const reducerMap = {
    [ResearchPageActionTypes.UPDATE_RESEARCH_COLUMN_FILTERS]: updateColumnFilters,
    [ResearchPageActionTypes.UPDATE_RESEARCH_GLOBAL_FILTER]: updateGlobalFilter,
    [ResearchPageActionTypes.UPDATE_RESEARCH_PAGINATION]: updatePagination,
    [ResearchPageActionTypes.SET_RESEARCH_SCROLL_TO]: setScrollToBackstopId,
};

export default handleActions<IMrtTableState, any>(reducerMap, initialState);