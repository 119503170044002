import * as React from "react";
import {capitalizeFirstLetter} from "../../utils/commonUtil";

export interface IRadioButtonsProps {
    id: string;
    currentValue: boolean | undefined;
    onChange: (a: boolean) => void;
    classNamePrefix: string;
    name: string;
    yesLabel?: string;
    noLabel?: string;
}
export const RadioButtonsComponent: React.FunctionComponent<IRadioButtonsProps> = (props) => {
    const renderRadioButton = (value: boolean) => {
        const checked = value === props.currentValue;
        const label = value ? props.yesLabel ? props.yesLabel : "Yes"
                        : props.noLabel ? props.noLabel : "No";
        return  <div className="shared-components__radio-buttons">
            <label className="radio-container">
                <input
                    type="radio"
                    name={props.name}
                    value={label}
                    checked={checked}
                    onChange={() => props.onChange(value)}
                    className={`${props.classNamePrefix}-radio-${value}`}
                />
                {capitalizeFirstLetter(label)}
                <span className="radio-checkmark"/>
            </label>
        </div>;
    };

    return <div className={props.classNamePrefix}>
        {renderRadioButton(true)}
        {renderRadioButton(false)}
    </div>;
};
