import {ISelectValue} from "../../common/Select.component";

export enum AssetClassFilter {
    EQUITY = "Equity",
    LIQUID_ALTERNATIVE = "Liquid Alternatives",
    FIXED_INCOME = "Fixed Income",
    MULTIASSET_DGFTTAA = "Multi Asset",
    CASH = "Cash",
    ALL = "All",
}

export enum AssetClass {
    EQUITY = "Equity",
    LIQUID_ALTERNATIVE = "Liquid Alternatives",
    FIXED_INCOME = "Fixed Income",
    MULTIASSET_DGFTTAA = "Multi Asset",
    CASH = "Cash",
}

export enum ManagerSortOption {
    RECENT_RATING = "recent",
    ALPHA_ASC = "alpha-asc",
    ALPHA_DESC = "alpha-desc",
}

export enum AdditionalFilterItem {
    DEFAULT = "DEFAULT",
    APPROACH = "approach",
    CATEGORY = "category",
    REGION = "region",
    STYLE = "style",
}

export const assetClassFilterItems: ISelectValue[] = [
    {id: AssetClassFilter.ALL, name: "All Asset Classes"},
    {id: AssetClassFilter.CASH, name: "Cash"},
    {id: AssetClassFilter.EQUITY, name: "Equity"},
    {id: AssetClassFilter.FIXED_INCOME, name: "Fixed Income"},
    {id: AssetClassFilter.LIQUID_ALTERNATIVE, name: "Liquid Alternatives"},
    {id: AssetClassFilter.MULTIASSET_DGFTTAA, name: "Multi Asset"},
];

export enum RatingTypeFilter {
    ALL = "All",
    BUY = "Buy",
    SELL = "Sell",
    QUALIFIED = "Qualified",
    NR = "Not Recommended",
    IR = "In Review",
    NOT_RATED = "Not Rated",
}

export const ratingFilterItems: ISelectValue[] = [
    {id: RatingTypeFilter.ALL, name: "All Ratings"},
    {id: RatingTypeFilter.BUY, name: "Buy"},
    {id: RatingTypeFilter.SELL, name: "Sell"},
    {id: RatingTypeFilter.QUALIFIED, name: "Qualified"},
    {id: RatingTypeFilter.NR, name: "Not Recommended"},
    {id: RatingTypeFilter.IR, name: "In Review"},
    {id: RatingTypeFilter.NOT_RATED, name: "Not Rated"},
];

export const managerSearchItems: ISelectValue[] = [
    {id: ManagerSortOption.RECENT_RATING, name: "Recently Rated"},
    {id: ManagerSortOption.ALPHA_ASC, name: "Manager (A-Z)"},
    {id: ManagerSortOption.ALPHA_DESC, name: "Manager (Z-A)"},
];

export const additionalFilterItems: ISelectValue[] = [
    {id: AdditionalFilterItem.DEFAULT , name: "Select an attribute..."},
    {id: AdditionalFilterItem.APPROACH , name: "Approach"},
    {id: AdditionalFilterItem.CATEGORY , name: "Category"},
    {id: AdditionalFilterItem.REGION , name: "Region"},
    {id: AdditionalFilterItem.STYLE , name: "Style"},
];

export const AssetClassMapper: Record<string, AssetClass> = {
    "Equity": AssetClass.EQUITY,
    "LiquidAlternative": AssetClass.LIQUID_ALTERNATIVE,
    "Cash": AssetClass.CASH,
    "FixedIncome": AssetClass.FIXED_INCOME,
    "MultiAssetDgfTtaa": AssetClass.MULTIASSET_DGFTTAA,
};

export const AssetClassMapperReverse: Record<AssetClass, string> = {
    [AssetClass.EQUITY]: "Equity",
    [AssetClass.LIQUID_ALTERNATIVE]: "LiquidAlternative",
    [AssetClass.CASH]: "Cash",
    [AssetClass.FIXED_INCOME]: "FixedIncome",
    [AssetClass.MULTIASSET_DGFTTAA]: "MultiAssetDgfTtaa",
};