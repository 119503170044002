import * as React from "react";
import {
    MaterialReactTable, MRT_Column,
    MRT_ColumnFiltersState, MRT_RowData,
    MRT_ToggleGlobalFilterButton,
    useMaterialReactTable,
    MRT_ColumnDef, MRT_PaginationState,
} from "material-react-table";
import {Box, IconButton, Tooltip} from "@mui/material";
import {
    bottomToolbarStyle, filterTextFieldStyle,
    headersStyle,
    MrtTableStyle,
    textStyle,
    topToolbarStyle
} from "./MrtTable.style";
import {ReactNode, useEffect, useState} from "react";
import {AonColors} from "../../../../css/Colors";

import {DEFAULT_PAGINATION, IMrtTableColumnFilter, IMrtTablePagination} from "../../model/MrtTableState.model";
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import {esES, frFR} from "@mui/material/locale";
import {getLocale, getTranslation} from "../../utils/translationUtil";
import {MRT_Localization_EN} from "material-react-table/locales/en";

export interface IMrtTableProps<T extends MRT_RowData> {
    columns: MRT_ColumnDef<T>[];
    data: T[];
    header: (() => ReactNode) | string;
    onColumnFiltersChange: (filters: IMrtTableColumnFilter[]) => void;
    onGlobalFilterChange: (value: string) => void;
    onPaginationChange: (pagination: IMrtTablePagination) => void;
    globalFilter: string;
    columnFilters: IMrtTableColumnFilter[];
    pagination: IMrtTablePagination;
    reset: boolean;
}

export const MrtTableComponent : React.FunctionComponent<IMrtTableProps<any>> = (props) => {
    const [showGlobalFilter, setShowGlobalFilter] = useState<boolean>(false);
    //need to set initial global filter to empty string to prevent MUI error in test
    const [globalFilter, setGlobalFilter] = useState<string>("");
    const [pagination, setPagination] = useState<MRT_PaginationState>(props.pagination);
    const [allowPaginationReset, setAllowPaginationReset] = useState<boolean>(false);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(props.columnFilters);

    const resetTableState = () => {
        setAllowPaginationReset(true);
        setShowGlobalFilter(false);
        setPagination(DEFAULT_PAGINATION);
        setGlobalFilter("");
        setColumnFilters([]);
    };

    useEffect(() => {
      setGlobalFilter(props.globalFilter);
    }, []);

    useEffect(() => {
        if (props.reset) {
            resetTableState();
        } else {
            setShowGlobalFilter(props.globalFilter !== "");
        }
    }, [props]);

    useEffect(() => {
        if (pagination.pageIndex === 0 && !allowPaginationReset) {
            const paginationFromProps = props.pagination;
            if (paginationFromProps.pageIndex !== 0 || paginationFromProps.pageSize !== DEFAULT_PAGINATION.pageSize)
            {
                setPagination(paginationFromProps);
            }
            setAllowPaginationReset(true);
        } else {
            props.onPaginationChange(pagination);
        }
    }, [pagination]);

    useEffect(() => {
        props.onColumnFiltersChange(columnFilters);
    }, [columnFilters]);

    useEffect(() => {
        props.onGlobalFilterChange(globalFilter);
    }, [globalFilter]);

    const customFilterFn = (row: any, id: string, filterValue: string[]) => {
        if (filterValue.length === 0) {
            return true;
        }
        filterValue = filterValue.map((item: string) => item.replace(/\s\(\d+\)/g, ""));
        return filterValue.includes(row.getValue(id));
    };

    const columns = props.columns.map(columnProps => ({
        ...columnProps,
        filterVariant: columnProps.filterVariant ? columnProps.filterVariant : "multi-select",
        filterFn: columnProps.filterVariant ? "contains" : customFilterFn,
        muiTableHeadCellProps:
            ({ column }: { column: MRT_Column<Record<string, any>> }) => ({ sx: headersStyle(column) }),
        muiTableBodyCellProps: { sx: textStyle("400", "14px", "18px", AonColors.AonNavy, "12px") },
        muiFilterTextFieldProps: { sx: filterTextFieldStyle },
    }));

    const tableHeader = () => {
        return (typeof props.header === "string")
            ? <h2 className="title-medium gray">{props.header}</h2>
            : props.header();
    };

    const handleFiltersChange = (filters: MRT_ColumnFiltersState) => {
        table.resetPageIndex(true);
        setColumnFilters(filters);
    };

    const handleGlobalFilterChange = (value: string) => {
        table.resetPageIndex(true);
        setGlobalFilter(value);
    };
    const theme = useTheme();
    const internationalization = getLocale() === "fr-FR"
        ? {locale: frFR, localization: MRT_Localization_FR}
        : {locale: esES, localization: MRT_Localization_EN};

    const table = useMaterialReactTable({
        columns,
        data: props.data,
        enableFacetedValues: true,
        initialState: { showColumnFilters: false },
        autoResetPageIndex: false,
        enableTopToolbar: true,
        enableBottomToolbar: true,
        enableColumnActions: false,
        enableSorting: false,
        columnFilterDisplayMode: "popover",
        enableToolbarInternalActions: true,
        globalFilterFn: "contains",
        renderToolbarInternalActions: ({ table }) => (
            <Box>
                <MRT_ToggleGlobalFilterButton table={table} />
                <Tooltip title={getTranslation("mrt-table.clear-filters", "Clear Filters")}>
                    <IconButton onClick={resetTableState} data-testid="clear-filters">
                        <i className="strategies-clear-icon-color fa-solid fa-filter-slash"/>
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: tableHeader,
        onColumnFiltersChange: handleFiltersChange,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onGlobalFilterChange: handleGlobalFilterChange,
        onPaginationChange: setPagination,
        // onPaginationChange: handlePaginationChange,
        state: {
            columnFilters,
            pagination,
            globalFilter,
            showGlobalFilter,
        },
        muiTableProps: {sx: MrtTableStyle},
        muiTablePaperProps: {sx: {boxShadow: "none"}},
        muiBottomToolbarProps: {sx: bottomToolbarStyle},
        muiTableHeadRowProps: {sx: {boxShadow: "none"}},
        muiTopToolbarProps: {sx: topToolbarStyle},
        icons: {
            SearchIcon: () => <i className="strategies-search-icon-color far far fa-search" />,
            SearchOffIcon: () => <i className="strategies-search-icon-color far fa-light fa-magnifying-glass-minus"/>,
        },
        localization: internationalization.localization,
    });

    return <div className="strategies-container">
        <ThemeProvider theme={createTheme(theme, internationalization.locale)}>
            <MaterialReactTable table={table}/>
        </ThemeProvider>
    </div>;
};