import {Action, Dispatch} from "redux";
import {MRT_PaginationState} from "material-react-table";
import {IMrtTableColumnFilter} from "../../model/MrtTableState.model";

export enum ResearchPageActionTypes {
    UPDATE_RESEARCH_COLUMN_FILTERS = "UPDATE_RESEARCH_COLUMN_FILTERS",
    UPDATE_RESEARCH_GLOBAL_FILTER = "UPDATE_RESEARCH_GLOBAL_FILTER",
    UPDATE_RESEARCH_PAGINATION = "UPDATE_RESEARCH_PAGINATION",
    SET_RESEARCH_SCROLL_TO = "SET_RESEARCH_SCROLL_TO",
}

export const updateColumnFiltersAction = (columnFilters: IMrtTableColumnFilter[]) => {
    const payload = {columnFilters};
    return (dispatch: Dispatch<Action<ResearchPageActionTypes>>) => {
        dispatch({type: ResearchPageActionTypes.UPDATE_RESEARCH_COLUMN_FILTERS, payload});
    };
};

export const updateGlobalFilterAction = (globalFilterState: string) => {
    const payload = {globalFilterState};
    return (dispatch: Dispatch<Action<ResearchPageActionTypes>>) => {
        dispatch({type: ResearchPageActionTypes.UPDATE_RESEARCH_GLOBAL_FILTER, payload});
    };
};

export const updatePaginationAction = (pagination: MRT_PaginationState) => {
    const payload = {pagination};
    return (dispatch: Dispatch<Action<ResearchPageActionTypes>>) => {
        dispatch({type: ResearchPageActionTypes.UPDATE_RESEARCH_PAGINATION, payload});
    };
};

export const setScrollToBackstopAction = (backstopId: number) => {
    const payload = {scrollToBackstopId: backstopId};
    return (dispatch: Dispatch<Action<ResearchPageActionTypes>>) => {
        dispatch({type: ResearchPageActionTypes.SET_RESEARCH_SCROLL_TO, payload});
    };
};

export default {
    updateColumnFiltersAction,
    updateGlobalFilterAction,
    updatePaginationAction,
    setScrollToBackstopAction
};